<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="菜单名称">
        <el-input v-model="queryForm.name" placeholder="" size="small"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="queryForm.status" placeholder="" size="small" clearable>
          <el-option v-for="s in queryForm.statusOption" :label="s.label" :value="s.value" :key="s.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small" v-show="hasAddPermision">新增菜单</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table v-loading="loading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              row-key="id"
              :default-expand-all="false"
              :data="tableData" style="width: 100%;">
      <el-table-column prop="name" label="菜单名称">
        <template #default="scope">
          <el-icon :class="scope.row.icon"></el-icon> {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="menuType" label="菜单类型">
        <template #default="scope">
          <span v-if="scope.row.menuType==1">目录</span>
          <span v-if="scope.row.menuType==2">菜单</span>
          <span v-if="scope.row.menuType==3">按钮</span>
        </template>
      </el-table-column>
      <el-table-column prop="seq" label="显示排序"/>
      <el-table-column prop="path" label="路径" :show-overflow-tooltip="true" width="300"/>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.status === 0 ? 'info' : 'success'" disable-transitions>
            {{ scope.row.status == 0 ? '禁用' : '正常' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="300" align="center" v-if="hasDeletePermision||hasEditPermision||hasAddPermision">
        <template #default="scope">
          <el-button icon="el-icon-plus" size="mini" @click="addChildMenu(scope.$index, scope.row)" v-if="scope.row.menuType!=3&&hasAddPermision">添加子级</el-button>
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" v-show="hasEditPermision">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除菜单['+scope.row.name+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger" v-show="hasDeletePermision">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        layout="total"
        :total="total"
    >
    </el-pagination>
  </div>

  <!--菜单信息新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="上级菜单" prop="parentName" class="label-color">
        <el-select clearable @clear="clearParentMenu" ref="ruleFormTreeSelect" v-model="ruleForm.parentName" placeholder="请选择菜单" fit-input-width="true"
                   style="width:100%">
          <el-option style="height: auto;padding: 0;">
            <el-tree expand-on-click-node="false" default-expand-all ref="tree" highlight-current="true"
                     :data="menuTree" node-key="id" :props="{class:customNodeClass}"
                     @node-click="handleNodeClick"/>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType" class="label-color">
        <el-select v-model="ruleForm.menuType" placeholder="请选择菜单类型" fit-input-width="true" style="width:100%">
          <el-option v-for="item in menuTypes" :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜单名称" prop="name" class="label-color">
        <el-input v-model="ruleForm.name" placeholder="请输入菜单名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="菜单路径" prop="path" class="label-color">
        <el-input v-model="ruleForm.path" placeholder="请输入菜单路径" clearable></el-input>
      </el-form-item>
      <el-form-item label="菜单图标" prop="icon" class="label-color">
        <el-input v-model="ruleForm.icon" placeholder="请输入菜单图标类名" clearable></el-input>
      </el-form-item>
      <el-form-item label="显示排序" prop="seq" class="label-color">
        <el-input-number v-model="ruleForm.seq" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="菜单状态" prop="status" class="label-color">
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="1">正常</el-radio>
          <el-radio label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveUserInfo" v-if="isSave==true">保存</el-button>
        <el-button type="primary" @click="editUserInfo" v-if="isSave==false">修改</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import menuApi from '@/api/system/menu'
import {ref} from "vue";
import {handleTree} from "@/utils/treeUtil";

export default {
  data() {
    return {
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      menuTypes: [
        {
          label: '目录',
          value: '1',
        },
        {
          label: '菜单',
          value: '2',
        },
        {
          label: '按钮',
          value: '3',
        },
      ],
      menuTree: [],
      queryForm: {
        name: '',
        status: '',
        statusOption: [
          {
            label: '正常',
            value: '1'
          },
          {
            label: '禁用',
            value: '0'
          }
        ]
      },
      loading: false,
      total: 0,
      pageSize: 15,
      curPage: 1,
      tableData: [],
      isSave: false,
      ruleForm: {
        id: '',
        parentName: '',
        parentId: '',
        name: '',
        seq: '',
        status: '',
        menuType: '',
        path: '',
        icon: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入菜单名称',
            trigger: 'blur',
          }
        ],
        seq: [
          {
            required: true,
            message: '请输入显示排序',
            trigger: 'blur',
          },
        ],
        menuType: [
          {
            required: true,
            message: '请输入菜单类型',
            trigger: ['blur','change'],
          },
        ],
        status: [
          {
            required: true,
            message: '请选择状态',
            trigger: ['blur','change'],
          },
        ]
      },
      dialogTitle: '',
      dialogVisible: false
    }
  },
  mounted() {
    this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/system/menuManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/system/menuManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/system/menuManage:delete');
    })
    this.queryMenu();
  },
  methods: {
    queryMenuTree(){
      menuApi.queryMenu({
        name: '',
        notContainButton:1,
      }).then(response=>{
        this.menuTree=handleTree(response.data.data,'name');
      });
    },
    customNodeClass(data, node) {
      console.log(node)
      if (data.isPenultimate) {
        return 'is-penultimate'
      }
      return ''
    },
    // 清空弹窗上级菜单选择
    clearParentMenu(){
      this.ruleForm.parentName = '';
      this.ruleForm.parentId = '';
    },
    handleNodeClick(data) {
      this.ruleForm.parentName = data.label;
      this.ruleForm.parentId = data.id;
      this.$refs.ruleFormTreeSelect.blur();// 让select失去焦点，收起下拉
    },
    submit() {
      this.curPage = 1;
      this.queryMenu();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryMenu();
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.queryMenu();
    },
    queryMenu() {
      menuApi.queryMenu({
        name: this.queryForm.name,
        status: this.queryForm.status,
        pageSize: this.pageSize,
        curPage: this.curPage,
      }).then(response => {
        this.tableData = handleTree(response.data.data);
        this.total = response.data.data.length;
      });
      this.queryMenuTree();// 获取菜单下拉树
    },
    handleEdit(index, row) {
      this.isSave = false;
      this.dialogTitle = '编辑菜单信息';
      this.dialogVisible = true;
      this.ruleForm.parentName = row.parentName;
      this.ruleForm.parentId = row.parentId;
      this.ruleForm.name = row.name;
      this.ruleForm.seq = row.seq;
      this.ruleForm.path = row.path;
      this.ruleForm.icon = row.icon;
      this.ruleForm.menuType = row.menuType;
      this.ruleForm.id=row.id;
      this.ruleForm.status = ref(row.status.toString());
      // 选中树中值，这里要加$nextTick，否则不生效
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(row.parentId);
      });
    },
    addInfo() {
      this.isSave = true;
      this.dialogTitle = '新增菜单';
      this.dialogVisible = true;
      this.ruleForm.seq = 1;
      this.ruleForm.status = ref('1')
      // 重置表单
      this.ruleForm.parentId = '';
    },
    // 添加子级
    addChildMenu(index,row){
      this.isSave = true;
      this.dialogTitle = '新增菜单';
      this.dialogVisible = true;
      this.ruleForm.seq = 1;
      this.ruleForm.status = ref('1')
      // 重置表单
      this.ruleForm.parentName = row.name;
      this.ruleForm.parentId = row.id;
      if (row.menuType == 1) {
        this.ruleForm.menuType = '2';
      } else if (row.menuType == 2) {
        this.ruleForm.menuType = '3';
      }
    },
    handleDelete(index, row) {
      menuApi.delMenu(row.id).then(response => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: 'success',
            offset: 40,
            duration: 2000
          });
          this.queryMenu();
        } else {
          this.$notify({
            title: response.data.msg,
            type: 'warning',
            offset: 40,
            duration: 2000
          });
        }
      });
    },
    saveUserInfo() {
      this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              // 调用接口保存
              menuApi.addMenu(this.ruleForm).then(response => {
                if (response.data.code == 1) {
                  this.$notify({
                    title: response.data.msg,
                    type: 'success',
                    offset: 40,
                    duration: 2000
                  });
                  // 关闭窗口
                  this.dialogVisible = false;
                  // 刷新列表
                  this.queryMenu();
                } else {
                  this.$notify({
                    title: '保存失败：' + response.data.msg,
                    type: 'warning',
                    offset: 40,
                    duration: 2000
                  });
                }
              });
            }
          }
      )
    },
    editUserInfo() {
      this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              // 调用接口保存
              menuApi.editMenu(this.ruleForm).then(response => {
                if (response.data.code == 1) {
                  this.$notify({
                    title: response.data.msg,
                    type: 'success',
                    offset: 40,
                    duration: 2000
                  });
                  // 关闭窗口
                  this.dialogVisible = false;
                  // 重置表单
                  // this.$refs['ruleForm'].resetFields();
                  // 刷新列表
                  this.queryMenu();
                } else {
                  this.$notify({
                    title: '修改失败：' + response.data.msg,
                    type: 'success',
                    offset: 40,
                    duration: 2000
                  });
                }
              });
            }
          }
      )
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
    },
  }
}
</script>

<style scoped>

</style>