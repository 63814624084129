/**
 * menu模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const menu = {
    // 菜单查询
    queryMenu(data) {
        return request({
            url: '/api/menu/queryMenu',
            method: 'post',
            data: data
        });
    },
    // 删除菜单
    delMenu(id) {
        return request({
            url: '/api/menu/delMenu/' + id,
            method: 'get'
        });
    },
    // 新增菜单
    addMenu(data) {
        return request({
            url: '/api/menu/addMenu',
            method: 'post',
            data: data
        });
    },
    // 编辑菜单
    editMenu(data) {
        return request({
            url: '/api/menu/editMenu',
            method: 'post',
            data: data
        });
    },
}

export default menu;